import { InputAdornment, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ControlledSelect({
  itemlist,
  label,
  onChange,
  deleteButton,
  usingTranslation,
  required,
  disabled,
  defaultValue,
  key,
}) {
  const [innerValue, setInnerValue] = useState();
  const [clear, setClear] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (clear === true) {
      setClear();
    }
  }, [clear]);
  return itemlist && !clear ? (
    <TextField
      key={key}
      variant="outlined"
      label={label}
      fullWidth
      defaultValue={defaultValue || ""}
      select
      disabled={disabled}
      required={required}
      onChange={(e, v) => {
        setInnerValue(v);
        onChange(e, v);
      }}
      InputProps={{
        endAdornment:
          innerValue && deleteButton ? (
            <InputAdornment position="start">
              <Button
                style={{
                  padding: 10,
                  minWidth: 0,
                  marginRight: 5,
                  borderRadius: 150,
                }}
                size="small"
                onClick={() => {
                  setInnerValue();
                  setClear(true);
                  onChange("", "");
                }}
              >
                <ClearIcon />
              </Button>
            </InputAdornment>
          ) : null,
      }}
    >
      {itemlist.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {usingTranslation
            ? typeof usingTranslation === "function"
              ? usingTranslation(option.value)
              : t(option.value)
            : option.label}
        </MenuItem>
      ))}
    </TextField>
  ) : null;
}
